<template>
  <v-dialog fullscreen light :value="openTerms">
    <v-card class="terms-of-use-dialog-content">
      <v-card-title class="justify-space-between">
        <span>Termos de Uso</span>
      </v-card-title>
      <terms-of-use></terms-of-use>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          depressed
          class="px-5"
          @click="aceitarTermoDeUso()"
        >
          Li e aceito todos os termos de uso
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    'terms-of-use': () => import("@/templates/terms-of-use.vue"),
  },
  computed: {
    ...mapGetters(['aceitouTermosUso', 'jwtData']),
    openTerms: function () {
      return this.jwtData && !this.aceitouTermosUso;
    },
  },
  methods: {
    aceitarTermoDeUso: function () {
      const usuarioId = this.$store.getters.jwtData.data.usuarioId;
      this.apiResource(`v1/acesso/controleacesso/${usuarioId}`)
        .save({
          termoUso: true,
        })
        .then(() => {
          this.$store.commit('updateTermoUso', true);
        });
    },
  },
};
</script>

<style lang="scss">
.terms-of-use-dialog-content {
  padding: 0 4rem !important;
}
</style>
